import axios from "axios"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../constants/constants"
// import objectToURLParam from "../../utils/objectToURLParam"

const useClientOptin = () => {
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getClientOptin = (authType, authValue) => {
    const authHeader =
      authType === "sugar" ? "X-Sugarcrm-Id" : "X-Authorization"

    const config = {
      headers: {
        [authHeader]: authValue,
      },
      url: `${BASE_URL}/clients/me/optins`,
      method: "GET",
    }

    return axios(config)
  }

  const putClientOptin = (authType, authValue, optinValues) => {
    const authHeader =
      authType === "sugar" ? "X-Sugarcrm-Id" : "X-Authorization"

    const config = {
      headers: {
        [authHeader]: authValue,
      },
      url: `${BASE_URL}/clients/me/optins`,
      method: "PUT",
      data: optinValues,
    }

    return axios(config)
  }

  return { getClientOptin, putClientOptin }
}

export default useClientOptin
