import { useContext } from "react"
import { AppContext } from "../AppContext"

const useAppContext = () => {
  const [state, setState] = useContext(AppContext)

  const clearAuth = () => setState({})

  const setUser = (user) =>
    setState((prevState) => ({
      ...prevState,
      user,
    }))

  const setAuthType = (authType) =>
    setState((prevState) => ({
      ...prevState,
      authType,
    }))

  return {
    context: state,
    setUser,
    setAuthType,
    clearAuth,
  }
}

export default useAppContext
