import Login from "./Login/Login"
import Preferences from "./Preferences/Preferences"

export const routes = [
  // This route is public by default, but redirects if user is not connected or doesnt have a url token
  {
    path: "/preferences",
    component: Preferences,
    exact: true,
  },
  {
    path: "/",
    component: Login,
    exact: true,
  },
]

export const protectedRoutes = []
