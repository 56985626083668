/* eslint-disable react/button-has-type */
import React from "react"
import PropTypes from "prop-types"

// Utils
import cn from "../../../utils/cn"

import styles from "./Button.module.css"

const Button = ({ children, type, onClick, className, ...btnProps }) => (
  <button
    {...btnProps}
    type={type}
    onClick={onClick}
    className={cn([styles.button, className])}
  >
    {children}
  </button>
)

Button.propTypes = {
  type: PropTypes.string,
}
Button.defaultProps = {
  type: "button",
}

export default Button
