/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react"
import queryString from "query-string"
import i18n from "i18next"
import { useKeycloak } from "@react-keycloak/web"

// Components
import Button from "../../components/atoms/Button/Button"

// Hooks
import useAppContext from "../../hooks/useAppContext"

// Utils
import { AUTH_REDIRECT } from "../../constants/constants"

import styles from "./Login.module.css"

const Login = ({ history, location }) => {
  const {
    setUser,
    context: { authType },
  } = useAppContext()
  const keycloak = authType === "keycloak" ? useKeycloak().keycloak : null

  const handleAuth = async () => {
    keycloak?.login({
      kcLocale: i18n.language,
      redirectUri: AUTH_REDIRECT,
    })
  }

  useEffect(() => {
    const { sugarToken } = queryString.parse(location.search)
    if (keycloak?.authenticated || sugarToken) {
      history.push({
        pathname: "/preferences",
        search: sugarToken ? `sugarToken=${sugarToken}` : "",
      })
    }
  }, [keycloak?.authenticated, history, location])

  return (
    <div className={styles.centerView}>
      <div className={styles.loginContainer}>
        <h3 className={styles.loginHeader}>Portail Client Mouratoglou</h3>
        <Button disabled={keycloak?.authenticated} onClick={() => handleAuth()}>
          Se connecter
          {keycloak?.authenticated ? (
            <span
              className="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Button>
      </div>
    </div>
  )
}

export default Login
