const fr = {
  translation: {
    dropdown: {
      camp: "Stages",
      extra: "Extras",
      accommodation: "Hébergements",
      yieldpackages: "Packages",
      fidPoints: "Gestion des points",
      fidAdmin: "Administration",
    },
    home: {
      book: "Rechercher",
    },
    offers: {
      offersCount: "{{ count }} offre trouvée",
      offersCount_plural: "{{ count }} offres trouvées",
      noOffer:
        "Aucun résultat. Veuillez redéfinir les dates et/ou les critères de votre recherche.",
      choosePackage: "Choisir ce stage",
      selected: "Séléctionné",
      priceFrom: "À partir de",
      details: "En détails",
      accommodation: "Hébergement",
      informationAboutPackage: "Information à propos de",
      watchPlanning: "Voir le planning type",
      book: "Réserver",
      weekNumber: "Semaine",
      campNumber: "Stage",
      noAccommodation: "Pas d'hébergement",
      search: "Recherche",
      calendar: "Calendrier",
      sports: "Sport",
      formats: "Format",
      submit: "Rechercher",
      more: "Autres",
      lastPlaces: "Dernières places disponibles",
      outSold: "Complet",
      new: "Nouveauté",
      lastMinute: "Dernière minute",
      legal:
        "L'hébergement sur le campus n’est pas disponible pour les enfants de moins de 10 ans.",
      legal2:
        "Le stage avec tournois est seulement disponible sur 2 semaines minimum.",
      accommodationWarning:
        "Attention, avec cet hébergement, le check-in se fera un jour plus tôt",
      modalWarn: "Attention",
      dropConfigurationPackage:
        "Souhaitez vous abandonner la configuration de votre {{ packageName }}?",
      abandonFinalize: "Abandonner et finaliser",
      continueConf: "Continuer la configuration",
      confirm: "Confirmer",
      cancel: "Annuler",
      youreAboutToSwitchOffer: "Vous allez remplacer l'offre de votre panier",
      youreAboutToSwitchExplanations:
        "En confirmant vous allez remplacer l'offre sélectionnée actuellement dans votre panier par cette nouvelle offre.",
      selectStage: "Sélectionnez votre stage",
      stageSelected: "Stage sélectionné",
      next: "suivant",
    },
    extras: {
      extras: "Choisissez vos extras",
      options: "Choisissez vos options",
      extrasNext: "Satisfait(e) de vos extras ?",
      optionsNext: "Satisfait(e) de vos options ?",
      next: "Suivant",
      addExtra: "Choisir",
      addOption: "Choisir",
      book: "Ajouter",
      weekNumber: "Semaine",
      campNumber: "Stage",
    },
    accommodations: {
      seeGallery: "Voir la galerie",
      selected: "Sélectionné",
      optional: "optionnel",
      title: "Choisissez votre hébergement",
      titleNext: "Satisfait(e) de votre hébergement ?",
      skip: "passer",
      next: "suivant",
      forYour: "Pour votre:",
      book: "Réserver",
      player: "Joueur",
      more: "voir plus",
      less: "voir moins",
      aboutToSwitch: "Vous allez remplacer l'hébergement de votre panier",
      aboutToSwitchText:
        "En confirmant vous allez remplacer l'hébergement actuellement dans votre panier par le dernier choisi.",
    },
    options: {
      confirm: "Confirmer",
      more: "En savoir plus",
      choose: "Choisir",
      continue: "Ajouter un stage",
      finish: "Finaliser ma commande",
    },
    players: {
      player: "Choisir le joueur",
      members: "Joueurs",
      pleaseSelect:
        "Veuillez sélectionner ou créer le joueur qui participera au stage.",
      form:
        "Veuillez remplir les informations relatives au joueur qui participera au stage.",
      personalInfo: "Informations personnelles",
      sportInfo: "Informations",
      new: "Nouveau joueur",
      firstname: "Prénom",
      lastname: "Nom",
      sexId: "Genre",
      nationalityId: "Nationalité",
      female: "Féminin",
      male: "Masculin",
      birthdate: "Date de naissance",
      email: "Email",
      phone: "Téléphone",
      day: "Jour",
      month: "Mois",
      year: "Année",
      isRanked: "Êtes-vous classé ?",
      otherRanking: "Autre classement",
      country: "Pays",
      years: "En quelle année avez-vous commencé la pratique ?",
      hoursPerWeek: "Combien d'heures jouez-vous par semaine ?",
      participationsPerYear: "À combien de matchs participez-vous par an ?",
      ranking: "Classement",
      next: "Suivant",
      back: "Retour",
      validate: "Valider",
      emergencyContactLastname: "Nom",
      emergencyContactFirstname: "Prénom",
      emergencyContactOccupation: "Qualité",
      emergencyContactPhone: "Téléphone",
      doctorLastname: "Nom",
      doctorAddress: "Adresse",
      doctorZipCode: "Code postal",
      doctorCity: "Ville",
      doctorCountryId: "Pays",
      socialSecurityAddress: "Adresse",
      socialSecurityZipCode: "Code postal",
      socialSecurityCity: "Ville",
      socialSecurityCountryId: "Pays",
      medicalObservations:
        "Observations complémentaires (Allergies, traitement en cours, ...)",
      medicalTermsAccepted:
        'J\'accepte les <a href="/medical-terms" target="_blank">conditions médicales</a>',
      responsible: "Contact d'urgence",
      doctor: "Médecin traitant",
      socialSecurity: "Sécurité sociale",
      levelIn: "Niveau en",
      languageLevel: "Niveau de langue",
      choosePicture: "Choisir une photo",
      acceptedFiles: "Fichiers acceptés",
    },
    breadcrumb: {
      offers: "Choix",
      accommodations: "Hébergements",
      extras: "Extras",
      options: "Options",
      players: "Coordonnées",
      recap: "Récapitulatif",
      payment: "Paiement",
    },
    basket: {
      selection: "Votre sélection",
      total: "Total",
      taxes: "Taxes incluses",
      delete: "Supprimer",
      finalize: "Finaliser la commande",
    },
    calendar: {
      monday: "lu",
      tuesday: "ma",
      wednesday: "me",
      thursday: "je",
      friday: "ve",
      saturday: "sa",
      sunday: "di",
    },
    price: "{{price, price}}",
    payment: {
      pay: "Payer",
      dontCloseTab:
        "Le paiement est en cours, êtes vous sûr de vouloir quitter ?",
      generalError:
        "Il y a eu une erreur lors de votre paiement. Veuillez réessayer ou contactez notre service client.",
    },
    middle: {
      players: {
        number: "Joueur",
        completeProfile: "Compléter le profil",
        addPlayer: "Ajouter un joueur",
        personalInfo: "Infos personnelles",
        sportsInfo: "Infos sportives",
        medicalInfo: "Infos médicales",
        plannedCamps: "Stage(s) prévu(s)",
        finishedCamps: "Stage(s) terminé(s)",
        modalPersonalInfo: "Infos personnelles",
        modalSportsInfo: "Infos sportives",
        modalMedicalInfo: "Infos médicales",
        modalSave: "Enregistrer",
      },
      orders: {
        date: "Date",
        cancellation: "Assurance annulation",
        amount: "Montant",
        balance: "Balance",
        invoice: "Facture",
        creditNotes: "Avoirs",
        yes: "Oui",
        no: "Non",
        back: "Précédent",
        taxesIncluded: "Taxes incluses",
        totalRegul: "Montant à régulariser",
        regularize: "Régulariser",
        useFromBalance: "utilisé de la balance",
      },
    },
  },
}

export default fr
