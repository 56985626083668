import React, { useState, useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import queryString from "query-string"
import Keycloak from "keycloak-js"
import { ReactKeycloakProvider } from "@react-keycloak/web"

// Hooks
import useAppContext from "./hooks/useAppContext"

// Constants
import { AUTH_CLIENT_ID, HOSTNAME, AUTH_HOSTNAME } from "./constants/constants"

// Components
import Routes from "./Routes"

// Context
import { AppProvider } from "./AppContext"

// utils
import "./translations/i18n"

const initConfig = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: `https://${HOSTNAME}/silent-check-sso.html`,
  promiseType: "native",
}

function App() {
  const { clearAuth } = useAppContext()

  const [keycloak, setKeycloak] = useState(null)
  const [authType, setAuthType] = useState(undefined)

  useEffect(() => {
    const { sugarToken } = queryString.parse(window.location.search)

    const auth = sugarToken ? "sugar" : "keycloak"
    setAuthType(auth)
    if (auth === "keycloak")
      setKeycloak(
        new Keycloak({
          realm: "mouratoglou",
          url: `https://${AUTH_HOSTNAME}/auth`,
          clientId: AUTH_CLIENT_ID,
        })
      )
  }, [])

  const keycloakListener = (event) => {
    switch (event) {
      case "onAuthLogout":
        clearAuth()
        break
      default:
    }
  }

  if (authType === undefined) return null
  if (authType === "sugar") {
    return (
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>
    )
  }
  if (authType === "keycloak" && keycloak) {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initConfig={initConfig}
        onEvent={keycloakListener}
      >
        <AppProvider>
          <Router>
            <Routes />
          </Router>
        </AppProvider>
      </ReactKeycloakProvider>
    )
  }
}

export default App
