const it = {
  translation: {
    home: {
      book: "Prenota",
    },
    offers: {
      offersCount: "{{ count }} offerta trovata",
      offersCount_plural: "{{ count }} offerte trovate",
      noOffer:
        "Nessun risultato. Ridefinisci le date e/o i criteri della tua ricerca.",
      choosePackage: "Seleziona questo programma",
      selected: "Selezionato",
      priceFrom: "A partire da",
      details: "Dettagli",
      accommodation: "Alloggio",
      informationAboutPackage: "Informazioni su",
      watchPlanning: "Consulta il programma standard",
      book: "Prenota",
      weekNumber: "Settimana",
      campNumber: "Programma",
      noAccommodation: "Nessun alloggio",
      search: "Ricerca",
      calendar: "Calendario",
      sports: "Sport",
      formats: "Formato",
      submit: "Ricerca",
      more: "Altri",
      lastPlaces: "Ultimi posti",
      outSold: "Completo",
      new: "Novità",
      lastMinute: "Ultimo minuto",
      legal:
        "L'alloggio sul campus non è disponibile per i bambini di età inferiore ai 10 anni.",
      legal2:
        "Il programma con tornei è disponibile solo per una durata minima di 2 settimane.",
      accommodationWarning:
        "Attenzione, se si seleziona questo allogio, il check-in verrà effettuato il giorno prima",
      selectStage: "Seleziona il tuo programma",
      stageSelected: "Programma selezionato",
      next: "Successivo",
      confirm: "Confermare",
      cancel: "Annullare",
      youreAboutToSwitchOffer: "SI SOSTITUISCE L'OFFERTA DEL TUO CARRELLO",
      youreAboutToSwitchExplanations:
        "Conferma di sostituire l'offerta attualmente selezionata nel tuo carrello con questa nuova offerta.",
    },
    extras: {
      extras: "Scegli il tuo extra",
      options: "Scegli il tuo opzione",
      extrasNext: "Soddisfatto del tuo extra ?",
      optionsNext: "Soddisfatto del tuo opzione ?",
      next: "Successivo",
      addExtra: "Aggiungi questo extra",
      addOption: "Aggiungi questa opzione",
      book: "Prenota",
      weekNumber: "Settimana",
      campNumber: "Programma",
    },
    accommodations: {
      seeGallery: "vedi la galleria",
      optional: "opzionale",
      selected: "selezionato",
      title: "Scegli il tuo allogio",
      titleNext: "Soddisfatto del tuo allogio ?",
      skip: "Saltare",
      next: "Successivo",
      forYour: "Per il tuo :",
      book: "Prenota",
      player: "Giocatore",
      more: "Maggiori informazioni",
      less: "Menori informazioni",
      aboutToSwitch: "Sostituirai il allogio del tuo carrello",
      aboutToSwitchText:
        "Confermando, sostituirai il allogio attualmente nel tuo carrello con l'ultimo scelto",
    },
    options: {
      confirm: "Conferma",
      more: "Maggiori informazioni",
      choose: "Seleziona",
      continue: "Aggiungi un programma",
      finish: "Finalizza il mio ordine",
    },
    players: {
      player: "Scegli il giocatore",
      members: "Membri",
      pleaseSelect:
        "Seleziona o crea il giocatore che parteciperà al programma.",
      form:
        "Qui troverà le informazioni relative al giocatore che prenderà parte al programma.",
      personalInfo: "Informazioni personali",
      sportInfo: "Informazioni",
      new: "Nuovo giocatore",
      firstname: "Nome",
      lastname: "Cognome",
      sexId: "Sesso",
      nationalityId: "Cittadinanza",
      female: "Femminile",
      male: "Maschile",
      birthdate: "Data di nascita",
      email: "E-mail",
      phone: "Telefono",
      day: "Giorno",
      month: "Mese",
      year: "Anno",
      isRanked: "Sei un giocatore professionista?",
      country: "Paese",
      years: "Quando hai iniziato a praticare?",
      hoursPerWeek: "Quante ore alla settimana ti alleni?",
      participationsPerYear: "Quante partite giochi ogni anno?",
      ranking: "Classifica",
      next: "Successivo",
      back: "Indietro",
      validate: "Conferma",
      emergencyContactLastname: "Cognome",
      emergencyContactFirstname: "Nome",
      emergencyContactOccupation: "In qualità de",
      emergencyContactPhone: "Telefono",
      doctorLastname: "Cognome",
      doctorAddress: "Indirizzo",
      doctorZipCode: "CAP",
      doctorCity: "Città",
      doctorCountryId: "Paese",
      socialSecurityAddress: "Indirizzo",
      socialSecurityZipCode: "CAP",
      socialSecurityCity: "Città",
      socialSecurityCountryId: "Paese",
      medicalObservations:
        "Informazioni complementari (allergie, trattamenti in corso...)",
      medicalTermsAccepted:
        'Accetto le <a href="/medical-terms" target="_blank">condizioni mediche</a>',
      responsible: "Recapiti di contatto per emergenze",
      doctor: "Medico curante",
      socialSecurity: "Sicurezza sociale francese",
      levelIn: "Livello di",
      languageLevel: "Livello linguistico",
      choosePicture: "Scegli una foto",
      acceptedFiles: "file accettati",
    },
    breadcrumb: {
      offers: "Selezione",
      extras: "Extra",
      options: "Opzioni",
      players: "Coordinate",
      recap: "Riepilogo",
      payment: "Pagamento",
      accommodations: "Alloggi",
    },
    basket: {
      selection: "La tua selezione",
      total: "Totale",
      taxes: "IVA incl.",
      delete: "Elimina",
      finalize: "Completa l’ordine",
    },
    calendar: {
      monday: "lun",
      tuesday: "mar",
      wednesday: "mer",
      thursday: "gio",
      friday: "ven",
      saturday: "sab",
      sunday: "dom",
    },
    price: "{{price, price}}",
    payment: {
      pay: "Pagare",
      dontCloseTab: "Il pagamento è in corso, sei sicuro di voler andare via?",
      generalError:
        "Qualcosa è andato storto con il pagamento, riprova o contatta il nostro servizio clienti.",
    },
    middle: {
      players: {
        number: "Giocatore",
        completeProfile: "Completa il profilo",
        addPlayer: "Aggiungi un giocatore",
        personalInfo: "Informazioni personali",
        sportsInfo: "Informazioni sportive",
        medicalInfo: "Informazioni mediche",
        plannedCamps: "Programma/i previsto/i",
        finishedCamps: "Programma/i terminato/i",
        modalPersonalInfo: "Informazioni personali",
        modalSportsInfo: "Informazioni sportive",
        modalMedicalInfo: "Informazioni mediche",
        modalSave: "Salva",
      },
      orders: {
        date: "Data",
        cancellation: "Assicurazione annullamento",
        amount: "Importo",
        balance: "Saldo",
        invoice: "Fattura",
        creditNotes: "Crediti",
        yes: "Sì",
        no: "No",
        back: "Indietro",
        taxesIncluded: "IVA incl.",
        totalRegul: "Totale da pagare",
        regularize: "Paga il mio ordine",
        useFromBalance: "importo utilizzato dal saldo",
      },
    },
  },
}

export default it
