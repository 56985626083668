import axios from "axios"

// Utils & misc
import { AUTHORIZATION_HOSTNAME } from "../../constants/constants"
// import objectToURLParam from "../../utils/objectToURLParam"

const useOptins = () => {
  const BASE_URL = `https://${AUTHORIZATION_HOSTNAME}/api/v1`

  const getOptins = (authType, authValue) => {
    const config = {
      headers: {},
      url: `${BASE_URL}/optins`,
      method: "GET",
    }

    return axios(config)
  }

  return { getOptins }
}

export default useOptins
