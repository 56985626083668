/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useCallback } from "react"
import queryString from "query-string"
import Toggle from "react-toggle"
import { useKeycloak } from "@react-keycloak/web"

// Components
import Button from "../../components/atoms/Button/Button"

// Utils
import cn from "../../utils/cn"
import { exportCollectionToArray } from "../../utils/collection"

// Hooks
import useAppContext from "../../hooks/useAppContext"
import useClientOptin from "../../hooks/api/useClientOptin"
import useOptins from "../../hooks/api/useOptins"

import "react-toggle/style.css"
import styles from "./Preferences.module.css"

const Preferences = ({ history, location }) => {
  const {
    context: { authType },
  } = useAppContext()
  const keycloak = authType === "keycloak" ? useKeycloak().keycloak : null

  const { getOptins } = useOptins()
  const { getClientOptin, putClientOptin } = useClientOptin()
  const savedGetOptins = useCallback(getOptins, [])
  const savedGetClientOptin = useCallback(getClientOptin, [])
  const [optins, setOptins] = useState([])
  const [optinOptions, setOptinOptions] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")

  const fetchClientOptin = useCallback(
    async (authType, authValue) => {
      try {
        const clientOptinResp = await savedGetClientOptin(authType, authValue)
        const optinsResp = await savedGetOptins()
        setOptinOptions(clientOptinResp.data)
        setOptins(exportCollectionToArray(optinsResp.data, "optinsSections"))
      } catch (error) {
        //
      }
    },
    [savedGetClientOptin]
  )

  useEffect(() => {
    const { sugarToken } = queryString.parse(location.search)
    if (!keycloak?.authenticated && !sugarToken) history.push("/")

    fetchClientOptin(
      authType,
      authType === "sugar" ? sugarToken : `Bearer ${keycloak?.token}`
    )
  }, [keycloak, location, history, fetchClientOptin, authType])

  const handleOptinChange = (optin) => {
    const editedOptin = { ...optinOptions }
    editedOptin.optins[optin].isOptin = !editedOptin.optins[optin].isOptin
    setOptinOptions(editedOptin)
  }

  const handleSubmit = async () => {
    const { sugarToken } = queryString.parse(location.search)
    if (!keycloak?.authenticated && !sugarToken) history.push("/")

    try {
      setSubmitLoading(true)
      setStatusMessage("")
      await putClientOptin(
        authType,
        authType === "sugar" ? sugarToken : `Bearer ${keycloak?.token}`,
        optinOptions
      )
      setSubmitLoading(false)
      setStatusMessage("Vos préférences sont sauvegardées")
    } catch (error) {
      //
      setSubmitLoading(false)
      setStatusMessage("Une erreur est survenue")
    }
  }

  return (
    <div className={styles.centerView}>
      <div className={styles.preferencesContainer}>
        <div className={styles.cardHeader}>
          <span className={styles.cardHeaderText}>
            Notifications: Gestion des préférences
          </span>
          <span className={styles.cardHeaderClose}>
            <label
              htmlFor="openSidebarMenu"
              className={styles.sidebarIconToggle}
            >
              <div
                className={cn([styles.spinner, styles.diagonal, styles.part1])}
              />
              <div className={cn([styles.spinner, styles.horizontal])} />
              <div
                className={cn([styles.spinner, styles.diagonal, styles.part2])}
              />
            </label>
          </span>
        </div>
        <div className={styles.cardContent}>
          <hr className="mt-0" />
          {optinOptions && optins ? (
            <table className={cn(["table table-borderless", styles.table])}>
              <thead>
                <tr className={styles.tableColHeader}>
                  <th scope="col" />
                  <th className={styles.tableColHeaderItem} scope="col">
                    <i className="fas fa-mobile-alt pr-2" />
                    SMS
                  </th>
                  <th className={styles.tableColHeaderItem} scope="col">
                    <i className="far fa-envelope pr-2" />
                    EMAIL
                  </th>
                </tr>
              </thead>
              <tbody>
                {optins.map((optin) => (
                  <tr
                    key={`key_${optin.sugarCrmSmsKey}`}
                    className={styles.tableItemRow}
                  >
                    <th className={styles.tableRowHeader} scope="row">
                      {optin?.intl?.fr?.name}
                      <div className={styles.tableRowHeaderDesc}>
                        {optin?.intl?.fr.description}
                      </div>
                    </th>
                    <td className={styles.tableColItem} colSpan="1">
                      <Toggle
                        className="toggle"
                        checked={
                          optinOptions.optins[optin.sugarCrmSmsKey].isOptin
                        }
                        icons={false}
                        onChange={() => handleOptinChange(optin.sugarCrmSmsKey)}
                      />
                    </td>
                    <td className={styles.tableColItem} colSpan="1">
                      <Toggle
                        className="toggle"
                        checked={
                          optinOptions.optins[optin.sugarCrmEmailKey].isOptin
                        }
                        icons={false}
                        onChange={() =>
                          handleOptinChange(optin.sugarCrmEmailKey)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.cardFooter}>
          <div className={styles.submit}>
            <Button
              disabled={submitLoading}
              className={styles.submitButton}
              onClick={handleSubmit}
            >
              {submitLoading ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              SAUVEGARDER
            </Button>
            {statusMessage ? (
              <div className={styles.statusMessage}>{statusMessage}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preferences
