/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useCallback } from "react"
import { Route, Switch /* useLocation */ } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import jwtDecode from "jwt-decode"

// Template
import WithRoot from "./templates/WithRoot"

// Hooks
import useAppContext from "./hooks/useAppContext"

// Routes
import { routes, protectedRoutes } from "./views/routes"

const Routes = () => {
  const {
    setUser,
    context: { authType },
  } = useAppContext()
  const keycloak = authType === "keycloak" ? useKeycloak().keycloak : null

  const savedSetUserInfo = useCallback(setUser, [])

  useEffect(() => {
    if (keycloak?.token) {
      const decoded = jwtDecode(keycloak?.token)
      setTimeout(() => {
        savedSetUserInfo(decoded)
      }, 1000)
    }
  }, [keycloak?.token, savedSetUserInfo])

  return (
    <WithRoot>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        {keycloak?.authenticated
          ? protectedRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))
          : null}
      </Switch>
    </WithRoot>
  )
}
export default Routes
