import React, { useState } from "react"
import queryString from "query-string"
import PropTypes from "prop-types"

const AppContext = React.createContext([{}, () => {}])

const whichAuth = () => {
  const { sugarToken } = queryString.parse(window.location.search)
  return sugarToken ? "sugar" : "keycloak"
}

const initContext = {
  user: null,
  errors: [],
  authType: whichAuth(),
}

const AppProvider = ({ children }) => {
  const [state, setState] = useState(initContext)

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppContext, AppProvider }
