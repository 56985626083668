const en = {
  translation: {
    home: {
      book: "Search",
    },
    offers: {
      offersCount: "{{ count }} offer found",
      offersCount_plural: "{{ count }} offers found",
      noOffer:
        "No results. Please select new dates and/or criteria for your search.",
      choosePackage: "Choose package",
      selected: "Selected",
      priceFrom: "Starting",
      details: "Details",
      accommodation: "Accommodation",
      informationAboutPackage: "Information about",
      watchPlanning: "See the example schedule",
      book: "Book",
      weekNumber: "Week",
      campNumber: "Camp",
      noAccommodation: "No accommodation",
      search: "Search",
      calendar: "Calendar",
      sports: "Sport",
      formats: "Format",
      submit: "Search",
      more: "Other",
      lastPlaces: "Final spots available",
      outSold: "Sold Out",
      new: "New",
      lastMinute: "Last minute",
      legal:
        "Accommodation on campus is not available for children under 10 years old.",
      legal2:
        "The tournament camp is only available for a minimum of two weeks.",
      accommodationWarning:
        "Warning, with this accommodation, check-in will be a day before",
      modalWarn: "Warning",
      dropConfigurationPackage:
        "Do you wish to abandon the configutation of your {{ packageName }}?",
      abandonFinalize: "Abandon and finalize",
      continueConf: "Continue to configure",
      confirm: "Confirm",
      cancel: "Cancel",
      youreAboutToSwitchOffer: "You are about to switch offers in your cart",
      youreAboutToSwitchExplanations:
        "If you confirm the action the offer currently in your cart will be replaced by the one you selected.",
      selectStage: "Select your camp",
      stageSelected: "Camp selected",
      next: "next",
    },
    accommodations: {
      seeGallery: "See photo gallery",
      selected: "Selected",
      optional: "optional",
      next: "next",
      title: "Select your accommodation",
      titleNext: "Pleased with your accommodation ?",
      skip: "skip",
      forYour: "About your :",
      book: "Book",
      player: "Player",
      more: "see more",
      less: "see less",
      aboutToSwitch: "You are about to switch accommodation in your cart",
      aboutToSwitchText:
        "If you confirm the action the accommodation currently in your cart will be replaced by the one you selected.",
    },
    extras: {
      extras: "Select your extras",
      options: "Select your options",
      extrasNext: "Pleased with your extras ?",
      optionsNext: "Pleased with your options ?",
      next: "Next",
      addExtra: "Select",
      addOption: "Select",
      book: "Book",
      weekNumber: "Week",
      campNumber: "Camp",
    },
    options: {
      confirm: "Confirm",
      more: "Learn more",
      choose: "Select",
      continue: "Add a camp",
      finish: "Finalize my order",
    },
    players: {
      player: "Choose player",
      members: "Members",
      pleaseSelect:
        "Please select or create the player who will be participating in the camp.",
      form:
        "Here you will find information about the player participating in the camp.",
      personalInfo: "Personal Information",
      sportInfo: "Informations",
      new: "New player",
      firstname: "First name",
      lastname: "Surname",
      sexId: "Gender",
      nationalityId: "Nationality",
      female: "Female",
      male: "Male",
      birthdate: "Date of birth",
      email: "Email",
      phone: "Telephone",
      day: "Day",
      month: "Month",
      year: "Year",
      isRanked: "Are you a professional player?",
      otherRanking: "Other ranking",
      country: "Country",
      years: "When did you start practising?",
      hoursPerWeek: "How many hours a week do you practice?",
      participationsPerYear: "How many matches do you play per year?",
      ranking: "Ranking",
      next: "Next",
      back: "Back",
      validate: "Confirm",
      emergencyContactLastname: "Surname",
      emergencyContactFirstname: "Name",
      emergencyContactOccupation: "Role",
      emergencyContactPhone: "Phone",
      doctorLastname: "Surname",
      doctorAddress: "Address",
      doctorZipCode: "Postcode",
      doctorCity: "City",
      doctorCountryId: "Country",
      socialSecurityAddress: "Address",
      socialSecurityZipCode: "Postcode",
      socialSecurityCity: "City",
      socialSecurityCountryId: "Country",
      medicalObservations:
        "Additional comments (allergies, current medical treatments, etc.)",
      medicalTermsAccepted:
        'I accept the  <a href="/medical-terms" target="_blank">medical terms and conditions</a>',
      responsible: "Person responsible",
      doctor: "Doctor",
      socialSecurity: "French social security",
      levelIn: "Level in",
      languageLevel: "Language fluency",
      choosePicture: "Choose a picture",
      acceptedFiles: "Accepted files",
    },
    breadcrumb: {
      offers: "Selection",
      accommodations: "Accommodations",
      extras: "Extras",
      options: "Options",
      players: "Contact info",
      recap: "Summary",
      payment: "Payment",
    },
    basket: {
      selection: "Your selection",
      total: "Total",
      taxes: "Taxes included",
      delete: "Delete",
      finalize: "Finalize your order",
    },
    calendar: {
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
      sunday: "Sun",
    },
    price: "{{price, price}}",
    payment: {
      pay: "Pay",
      dontCloseTab: "Payment in process, are you sure you want to leave ?",
      generalError:
        "Something went wrong with the payment, try again or contact our customer service.",
    },
    middle: {
      players: {
        number: "Player",
        completeProfile: "Complete the profile",
        addPlayer: "Add a player",
        personalInfo: "Personal info",
        sportsInfo: "Sport info",
        medicalInfo: "Medical info",
        plannedCamps: "Planned camp(s)",
        finishedCamps: "Completed camp(s)",
        modalPersonalInfo: "Personal info",
        modalSportsInfo: "Sport info",
        modalMedicalInfo: "Medical info",
        modalSave: "Save",
      },
      orders: {
        date: "Date",
        cancellation: "Cancellation insurance",
        amount: "Amount",
        balance: "Balance",
        invoice: "Invoice",
        creditNotes: "Credit note",
        yes: "Yes",
        no: "No",
        back: "Previous",
        taxesIncluded: "Taxes included",
        totalRegul: "Total to be paid",
        regularize: "Settle my order",
        useFromBalance: "amount used from your account balance: ",
      },
    },
  },
}

export default en
