import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import fr from "./fr"
import en from "./en"
import it from "./it"

i18n.use(initReactI18next)

i18n.init({
  debug: false,
  lng: "fr",
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === "price") {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        return new Intl.NumberFormat("fr", options).format(value)
      }

      return value
    },
  },
  resources: {
    fr,
    en,
    it,
  },
})

export default i18n
